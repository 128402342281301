import React from 'react';
import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: '#4E37B1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      ></div>
      <GlobalStyle />
    </>
  );
}

export default App;
