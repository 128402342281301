import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      font-family: 'Open Sans', sans-serif;
      box-sizing: border-box;
    }

    html, body, #root {
      height: 100%;
    }

    body {
      -webkit-font-smoothing: antialiased !important;
    }

    section.chartBoxs {
      display: flex;
      flex: 0 0 100%;
      height: 250px;
      margin: 20px 0;
      padding: 10px;
    }
`;
